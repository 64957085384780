import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import { SelectList } from "../../shared/SelectList";
import * as s from "../settingsSelectors";
import * as lookupSelectors from "../../lookups/lookupSelectors";
import {getNotificationSettings, uploadAgencyLogo, uploadLogo, uploadFavicon, updateAgencySettings} from "../settingsActions";
import FormCustomizations from "./FormCustomizationsComponent";
import NotificationSettings from "./NotificationSettingsComponent";
import { KeyValueModel } from "../../shared/SharedModels";
import LogoSettings from "./LogoSettingsComponent";
import {getProfile} from '../../shared/sharedActions';
import {UserProfileModel} from "../../shared/SharedModels";
import {profileSelector} from "../../shared/sharedSelectors";

import './settingsPage.scss';
import AgencySettings from "./AgencySettingsComponent";

type SettingsProps = {
    profile: UserProfileModel | undefined,
    language: string,
    languages: KeyValueModel[],
    logo: string,

    getNotificationSettings: typeof getNotificationSettings,
    uploadLogo: typeof uploadLogo,
    uploadAgencyLogo: typeof uploadAgencyLogo,
    uploadFavicon: typeof uploadFavicon
    updateAgencySettings: typeof updateAgencySettings
};

const Settings = (props: SettingsProps) => {
    const { profile, language, languages, logo, getNotificationSettings } = props;

    const organization = profile?.organization;

    useEffect(() => {
        getNotificationSettings();
    }, [organization]);

    const handleLanguageChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
    }

    return (
        <>
            <Box id="settings-container" >
                <Box className="filter-container">
                    <Typography>Language</Typography>
                    <SelectList id="language"
                        name="language"
                        className="select-holder filter-list"
                        value={language}
                        onChange={handleLanguageChange}
                        items={languages}
                        // helperText={validation.getErrorText(validationErrors, 'certificationType')}
                    />
                </Box>

                <NotificationSettings organization={organization} />
                <FormCustomizations />
                <LogoSettings logo={logo} uploadLogo={props.uploadLogo} />
                {(profile && profile?.agencyDetails?.length > 0 && profile.whiteLabel && profile.whiteLabel.organizationId == profile.organizationId) ?
                    <AgencySettings
                        agencyOrganisationId={profile?.agencyDetails[0].id}
                        agencyLogo={profile?.whiteLabel?.agencyLogo || ""}
                        favicon={profile?.whiteLabel?.favicon}
                        whiteLabelOrganisationId={profile?.whiteLabel?.organizationId}
                        uploadAgency={props.uploadAgencyLogo}
                        uploadFavicon={props.uploadFavicon}
                        updateAgencySettings={props.updateAgencySettings}
                        agencySettings={
                            {
                                domainName: profile?.whiteLabel?.domainName ?? '',
                                organisationId: profile?.whiteLabel?.organizationId ?? 0,
                                termsService: profile?.whiteLabel?.termsService ?? '',
                                privacyPolicy: profile?.whiteLabel?.privacyPolicy ?? '',
                                cookiePolicy: profile?.whiteLabel?.cookiePolicy ?? '',
                                fromReplyEmail: profile?.whiteLabel?.fromReplyEmail ?? '',
                                pageTabTitle: profile?.whiteLabel?.pageTabTitle ?? '',
                            }
                        }
                    />
                : null}
            </Box>
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        profile: profileSelector(state),
        csatSettings: s.csatSettingsSelector(state),
        language: s.languageSelector(state),
        logo: s.logoSelector(state),
        languages: lookupSelectors.languagesSelector(state),
    }
}

const mapDispatchToProps = { getNotificationSettings, uploadLogo, uploadAgencyLogo, uploadFavicon, updateAgencySettings };

const SettingsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);

export default SettingsContainer;
