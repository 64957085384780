import React, {useEffect} from "react";
import {Box, Snackbar} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import '../setupPage.scss';
import {PanelStateModel, TemplateModel, TextTemplateModel} from "../SetupModels";
import Preview from './PreviewComponent';
import {connect} from 'react-redux';
import {ApplicationState} from "../../ApplicationState";
import {getTemplate, updateTemplate, changeSaveTemplateFlag, getTextTemplate, updatePanelState} from "../setupActions";
import {
    loadLanguages,
    loadTemplateActions,
    loadFontSizes,
    loadFontFamilies,
    loadIconSets,
    loadTextSets,
    loadTitleSpacings,
    loadIconSpacings,
    loadIconSizes,
    loadColorPalette,
} from '../../lookups/lookupActions';
import * as selectors from '../setupSelectors';
import * as sharedSelectors from '../../shared/sharedSelectors';
import * as lookupSelectors from '../../lookups/lookupSelectors';
import {KeyValueModel, UserProfileModel} from "../../shared/SharedModels";
import SectionComponent from "../../shared/components/SectionComponent";
import CustomizePanel from "./CustomizePanel";
import IconTemplate from "./IconTemplateComponent";
import CustomizeIcons from "./CustomizeIcons";
import Loading from "../../shared/components/Loading";
import {useStepperDispatch} from "./SetupStepper";
import {TextSets} from "../../lookups/LookupsApiData";

type CustomizeTemplatePageProps = {
    template?: TemplateModel,
    textTemplate?: TextTemplateModel,
    isSaved: boolean,
    profile: UserProfileModel | undefined,
    actions: KeyValueModel[],
    fontSizes: KeyValueModel[],
    titleSpacings: KeyValueModel[],
    iconSizes: KeyValueModel[],
    iconSpacings: KeyValueModel[],
    fontFamilies: KeyValueModel[],
    colorPalette: KeyValueModel[],
    iconSets: KeyValueModel[],
    templateLanguages: KeyValueModel[],
    textSets: TextSets[]

    getTemplate: typeof getTemplate,
    getTextTemplate: typeof getTextTemplate,
    changeSaveTemplateFlag: typeof changeSaveTemplateFlag,
    updateTemplate: typeof updateTemplate,

    loadTemplateActions: typeof loadTemplateActions,
    loadLanguages: typeof loadLanguages,
    loadFontSizes: typeof loadFontSizes,
    loadFontFamilies: typeof loadFontFamilies,
    loadIconSets: typeof loadIconSets,
    loadTextSets: typeof loadTextSets,
    loadTitleSpacings: typeof loadTitleSpacings,
    loadIconSpacings: typeof loadIconSpacings,
    loadIconSizes: typeof loadIconSizes,
    loadColorPalette: typeof loadColorPalette,
    surveySetType?: string
    panelState: PanelStateModel
    updatePanelState: typeof updatePanelState
};

const CustomizeTemplatePage = (props: CustomizeTemplatePageProps) => {
    const dispatch = useStepperDispatch();
    const {
        template,
        textTemplate,
        actions,
        fontSizes,
        titleSpacings,
        iconSizes,
        iconSpacings,
        fontFamilies,
        colorPalette,
        iconSets,
        templateLanguages,
        isSaved,
        changeSaveTemplateFlag,
        getTemplate,
        getTextTemplate,
        loadIconSets,
        loadTextSets,
        loadTitleSpacings,
        loadFontSizes,
        loadTemplateActions,
        loadIconSpacings,
        loadIconSizes,
        loadFontFamilies,
        loadColorPalette,
        textSets,
        surveySetType,
        panelState,
        updatePanelState
    } = props;

    const handleAlertClose = () => {
        changeSaveTemplateFlag(false);
    }

    const organization = props.profile?.organization;

    useEffect(() => {
        if (!actions.length) loadTemplateActions();
        if (!iconSets.length) loadIconSets();
        if (!titleSpacings.length) loadTitleSpacings();
        if (!fontSizes.length) loadFontSizes();
        if (!iconSpacings.length) loadIconSpacings();
        if (!iconSizes.length) loadIconSizes();
        if (!fontFamilies.length) loadFontFamilies();
        if (!colorPalette.length) loadColorPalette();
    }, [])

    useEffect(() => {
        if (template && template.id) {
            dispatch({type: 'setCanGoForward', payload: true});
        }
    }, [template]);

    useEffect(() => {
        getTemplate();
        getTextTemplate()
    }, [organization]);

    useEffect(() => {
        if (textTemplate?.languageId) {
            loadTextSets(textTemplate?.languageId)
        }
    }, [textTemplate?.languageId]);

    return (
        <>
            {template &&
                <Box id='template-container'>
                    <SectionComponent marginTop="0">Preview Survey</SectionComponent>
                    {template && textTemplate && actions.length && fontSizes.length && fontFamilies.length && colorPalette.length && iconSets.length && textSets.length
                        ? <Preview
                            template={template}
                            textTemplate={textTemplate}
                            actions={actions}
                            fontSizes={fontSizes}
                            titleSpacings={titleSpacings}
                            iconSizes={iconSizes}
                            iconSpacings={iconSpacings}
                            fontFamilies={fontFamilies}
                            colorPalette={colorPalette}
                            iconSets={iconSets}
                            templateLanguages={templateLanguages}
                            textSets={textSets}
                            surveySetType={surveySetType}
                        /> : <Loading message={"Loading Preview"}/>}
                    <Box style={{display: "flex", justifyContent: "space-between"}}>
                        <Box style={{display: "flex", flexDirection: "column", width: "100%", marginRight: "50px"}}>
                            <SectionComponent
                                marginTop="10px"
                                collapseButtonAction={() => {
                                    updatePanelState({callToAction: !panelState.callToAction, icons: !panelState.icons})
                                }}
                                collapseButtonState={panelState.callToAction}
                            >Customize Call to Action</SectionComponent>
                            {template && panelState.callToAction &&
                                <CustomizePanel template={template} textTemplate={textTemplate}
                                                surveySetType={surveySetType}/>
                            }
                        </Box>
                        <Box style={{display: "flex", flexDirection: "column"}}>
                            <SectionComponent
                                marginTop="10px"
                                collapseButtonAction={() => {
                                    updatePanelState({callToAction: !panelState.callToAction, icons: !panelState.icons})
                                }}
                                collapseButtonState={panelState.icons}
                            >Customize Template</SectionComponent>
                            {template && panelState.icons &&
                                <CustomizeIcons template={template} textTemplate={textTemplate}
                                                surveySetType={surveySetType}/>
                            }
                        </Box>
                    </Box>

                    <SectionComponent
                        marginTop="10px"
                        collapseButtonAction={() => updatePanelState({iconTemplate: !panelState.iconTemplate})}
                        collapseButtonState={panelState.iconTemplate}
                    >{surveySetType === 'Images' ? "Select Icon Template" : "Select Text Template"}</SectionComponent>
                    {template && template.iconSet && panelState.iconTemplate &&
                        <IconTemplate template={template} textTemplate={textTemplate} surveySetType={surveySetType}/>
                    }

                </Box>
            }
            <Snackbar open={isSaved} onClose={handleAlertClose} autoHideDuration={1000}>
                <Alert variant="filled" severity="success">
                    SAVED
                </Alert>
            </Snackbar>
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        template: selectors.templateSelector(state),
        textTemplate: selectors.textTemplateSelector(state),
        profile: sharedSelectors.profileSelector(state),
        isSaved: selectors.savedTemplateSelector(state),

        actions: lookupSelectors.actionTypesSelector(state),
        fontSizes: lookupSelectors.fontSizeTypesSelector(state),
        titleSpacings: lookupSelectors.titleSpacingTypesSelector(state),
        iconSizes: lookupSelectors.iconSizeTypesSelector(state),
        iconSpacings: lookupSelectors.iconSpacingTypesSelector(state),
        fontFamilies: lookupSelectors.fontFamilyTypesSelector(state),
        colorPalette: lookupSelectors.colorPaletteSelector(state),
        iconSets: lookupSelectors.iconSetsSelector(state),
        templateLanguages: lookupSelectors.templateLanguagesSelector(state),
        textSets: lookupSelectors.textSetsSelector(state),
        surveySetType: sharedSelectors.surveySetTypeSelector(state),
        panelState: selectors.panelStateSelector(state)
    }
}

const mapDispatchToProps = {
    getTemplate,
    getTextTemplate,
    updateTemplate,
    changeSaveTemplateFlag,
    loadLanguages,
    loadTemplateActions,
    loadFontSizes,
    loadFontFamilies,
    loadIconSets,
    loadTextSets,
    loadTitleSpacings,
    loadIconSpacings,
    loadIconSizes,
    loadColorPalette,
    updatePanelState
};

const CustomizeTemplate = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizeTemplatePage);

export default CustomizeTemplate;
