import InfoIcon from "@material-ui/icons/Info";
import {Tooltip, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

type FeedbackOption = {
    name: string;
    isActive: boolean;
    isSelected: boolean;
    onClick: () => void;
    tooltipText?: string;
    isDisabled?: boolean;
};
const useStyles = makeStyles((theme) => ({
    toolTipText: {
        fontSize: "0.8rem",
        lineHeight: "125%",
        textAlign: "center"
    },
    feedbackButton: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        borderRadius: '400px',
        boxShadow: '0 3px 1px rgba(0, 0, 0, 0.1)',
        display: "inline-flex",
        flexDirection: "row",
        backgroundColor: "#FFFFFF",
        alignItems: "center",
        justifyContent: "space-evenly",
        transition: "all 0.2s ease-in-out",
        padding: '0.6rem 0.5rem',
        width: "15.5rem",
        color: '#4b5a7a',
        '&:hover': {
            backgroundColor: "#F5F5F5",
            boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)',
        }
    },
    infoIcon: {
        paddingLeft: "0.5rem",
        color: "#3B76EF",
        justifySelf: "flex-end",
    },
}));
const FeedbackOption: React.FC<FeedbackOption> = (props) => {
    const classes = useStyles();
    const {
        name,
        isActive,
        isSelected,
        onClick,
        tooltipText,
        isDisabled
    } = props;

    let buttonContent = null;

    if (name === "InternalReviewForm") {
        buttonContent = <Typography>Internal Review Form</Typography>;
    }
    if (name === "ExternalReviewForm") {
        buttonContent = <Typography>External Review Form</Typography>;
    }
    if (name === "ReviewSites") {
        buttonContent = <Typography>Review Sites (Default)</Typography>;
    }
    if (name === "ReviewFunnel") {
        buttonContent = <Typography>Review Funnel</Typography>;
    }
    return (
        <button onClick={onClick} className={`${classes.feedbackButton}`} disabled={isDisabled}>
            {isSelected && isActive ?
                <RadioButtonCheckedIcon style={{color: "#3B76EF", width: '2rem', marginRight: '0.5rem'}} fontSize={"large"}/> :
                <RadioButtonUncheckedIcon style={{color: "#c8dafe", width: '2rem', marginRight: '0.5rem'}} fontSize={"large"}/>}
            {buttonContent}
            {tooltipText &&
            <Tooltip
                title={<p className={`${classes.toolTipText}`}>{tooltipText}</p>}
                placement="right-end"
                enterTouchDelay={0}
                interactive={true}
                arrow>
                <InfoIcon className={`${classes.infoIcon}`}/>
            </Tooltip>}
        </button>
    );
}

export default FeedbackOption;
